import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router-dom";

import { Button, Col, Container, Row } from 'react-bootstrap';

import Footer from '../Footer';
import TDLogo from '../common/TDLogo';
import styles from './LoginForm.scss';

function SecurityQuestionsForm() {
    const { t } = useTranslation();

    return (
        <Fragment>
            <div>
                <div className={`td-error ${styles[`forgot-password-spinner`]}`}>
                    {t('td.fx.forgot.password.cannot.retrieve.security.questions')}
                </div>
                <Button as="a" href="/" className="btn td-btn-secondary-clear btn">{t('td.fx.signing.cancel')}</Button>
            </div> : null
        </Fragment>
    );
}

function ForgotPassword() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();


    return (
        <Row className={`${styles.fullHeight} + g-0`}>
            <Col xs={12} sm={6} md={5} lg={4} className={`g-0 ${styles.loginWrapper}`}>
                <Container style={{ padding: '4em', boxSizing: 'border-box' }}>
                    <TDLogo/>
                    <SecurityQuestionsForm />
                </Container>
                <Footer login={true}/>
            </Col>
            <Col xs={12} sm={6} md={7} lg={8} className={`g-0 ${styles.loginBackground}`}>
            </Col>
        </Row>
    )
}

export default ForgotPassword;